import { viewerQuery } from ':src/apollo/auth/queries';
import { useSuspenseQuery } from '@apollo/experimental-nextjs-app-support/ssr';

export default function useViewer() {
  const {
    data: { viewer },
  } = useSuspenseQuery(viewerQuery);
  const isLoggedIn = viewer.scope === 'user';

  return {
    viewer,
    isLoggedIn,
    hasActiveCESubscription: viewer.featureFlags?.includes('umpr--bypass-subscription')
      ? isLoggedIn
      : Boolean(viewer.canAccessMotivoLearning),
  };
}
