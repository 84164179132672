import { Box } from '@mui/material';
import CourseCard, { PartialCourse } from './CourseCard';
import MoGrid from './MoGrid';

export default function CourseCarousel({ courses }: { courses: PartialCourse[] }) {
  return (
    <Box sx={{ m: -3, p: 3, overflowX: { xs: 'scroll', sm: 'auto' } }}>
      <Box width={{ xs: 1000, sm: '100%' }}>
        <MoGrid container>
          {courses.map((course, i) => (
            <MoGrid item xs={4} sm={6} md={4} key={course.slug}>
              <Box display={{ sm: i === 2 ? 'none' : 'block', md: 'block' }}>
                <CourseCard course={course} isVertical />
              </Box>
            </MoGrid>
          ))}
        </MoGrid>
      </Box>
    </Box>
  );
}
