import { ReactNode } from 'react';
import { Box, Typography, Paper, useTheme, useMediaQuery } from '@mui/material';
import { ANNUAL_PRICE } from ':src/constants';
import CheckBulletList from './CheckBulletList';
import MoButtonLink from './MoButtonLink';

const ResponsiveWrapper = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Paper elevation={0} sx={{ borderTop: `18px solid ${theme.palette.primary.main}` }}>
        <Box p={5}>{children}</Box>
      </Paper>
    );
  }

  return <>{children}</>;
};

export default function PricingTable() {
  return (
    <ResponsiveWrapper>
      <Typography variant="h3" mb={2}>
        For a limited time, our annual plan is {ANNUAL_PRICE.toFormat('$0')}
      </Typography>
      <Box mb={3}>
        <CheckBulletList
          items={[
            'CE panels and events featuring Motivo’s diverse network of clinical providers',
            'Easy tracking of your credits and certificates',
            'A growing library of on-demand courses with topics such as access, equity, and cultural humility',
          ]}
        />
      </Box>
      <MoButtonLink fullWidth href="/signup">
        Get started
      </MoButtonLink>
    </ResponsiveWrapper>
  );
}
