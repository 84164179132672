import { Box, styled, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const List = styled('ul')({
  margin: 0,
  padding: 0,
});

const ListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  '&:not(:first-of-type)': {
    marginTop: theme.spacing(1),
  },
}));

export default function CheckBulletList({ items }: { items: string[] }) {
  return (
    <List>
      {items.map((item, i) => (
        <ListItem key={i}>
          <Box mr={2}>
            <CheckIcon />
          </Box>
          <Typography>{item}</Typography>
        </ListItem>
      ))}
    </List>
  );
}
